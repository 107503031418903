.header {
  background-color: #037f97;
}

.header-logo {
  display: flex;
  margin: 0 100px;
}

.logo {
  height: 200px;
  width: 200px;
  margin: 20px;
  margin-bottom: 20px;
}

.logo:hover {
  height: 210px;
  width: 210px;
  margin: 15px;
}

.header-space {
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.title {
  color: #eee;
  font-size: 4rem;
  align-self: center;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px rgb(57, 57, 57);
}

.narrow-title-parent {
  display: none;
}

.title-narrow {
  margin-top: 30px;
  margin-bottom: 30px;
}

.wide-title-parent {
  position: absolute;
  right: 400px;
  left: 400px;
  top: 6rem;
  z-index: 1;
}

.title-wide {
  text-align: center;
  margin-bottom: 30px;
}

.subtitle {
  color: #eee;
  font-size: 1rem;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

a {
  text-decoration: none;
}

.google-play-area {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
}

.google-play {
  width: 200px;
  padding: 0 10px;
}

.qrcode {
  height: 140px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.qrcode:hover {
  height: 146px;
  width: 146px;
}

@media screen and (max-width: 1500px) {
  .header-logo {
    display: flex;
    margin: 0;
  }

  .wide-title-parent {
    right: 230px;
    left: 230px;
  }
}

@media screen and (max-width: 900px) {
  .google-play-area {
    flex-direction: row;
  }
  .qrcode {
    height: 70px;
    margin-left: 5px;
    margin-right: 10px;
  }
  .title {
    font-size: 3rem;
  }
  .qrcode:hover {
    height: 75px;
    width: 75px;
    margin-left: 0;
  }
  .header-space {
    display: flex;
  }
  .wide-title-parent {
    display: none;
  }
  .narrow-title-parent {
    display: block;
  }
}

@media screen and (max-width: 650px) {
  .logo {
    height: 150px;
    width: 150px;
    margin: 15px;
  }

  .logo:hover {
    height: 160px;
    width: 160px;
    margin: 10px;
  }

  .header-logo {
    flex-direction: column;
    align-items: center;
  }
  .google-play {
    position: relative;
  }

  .header-space {
    flex-direction: column;
    margin-top: 0;
  }

  .google-play {
    padding: 0px;
  }

  .google-play-area {
    align-items: center;
    position: relative;
  }

  .qrcode {
    height: 70px;
    margin-left: 0;
    margin-right: 0;
  }

  .title-narrow {
    margin: 0;
  }
}

@media screen and (max-width: 450px) {
  .logo {
    height: 100px;
    width: 100px;
    margin: 10px;
  }
  .logo:hover {
    height: 104px;
    width: 104px;
    margin: 8px;
  }
  .title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 300px) {
  .title {
    font-size: 1rem;
  }

  .subtitle {
    display: none;
  }

  .google-play {
    width: 160px;
    margin: 5px;
  }

  .qrcode {
    display: none;
  }
}
