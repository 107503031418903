* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: lato, sans-serif;
  color: rgb(57, 57, 57);
}

a {
  color: #037f97;
}
.overflow {
  position: absolute;
  top: 150px;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
.parent {
  position: relative;
}
.qr-code-parent {
  position: fixed;
  width: 500px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index:2
}

.qr-code-big {
  width: 100%;
  border: 2px solid #037f97;
  -webkit-box-shadow: 0 0 5px 2px #037f97;
  -moz-box-shadow: 0 0 5px 2px #037f97;
  box-shadow: 0 0 5px 2px #037f97;
}

.close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #037f97;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.steps-items div {
  margin: 5px 20px;
}

@media screen and (max-width: 650px) {
  .qr-code-parent {
    width: 300px;
  }
  .close {
    right: 12px;
    top: 12px;
    width: 24px;
    height: 24px;
  }
}
@media screen and (max-width: 320px) {
  .qr-code-parent {
    width: 100%;
    padding: 0 10px;
  }
  .close {
    right: 30px;
    top: 8px;
    width: 12px;
    height: 12px;
  }
}
