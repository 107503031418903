.portals-title {
  margin-bottom: 10px;
  font-size: 1rem;
  text-align: center;
  color: #037f97;
}

.realEstatePortals li {
  list-style: none;
}

.realEstatePortals {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.realEstatePortals li a:hover {
  background-color: #037f97;
}

.realEstatePortals li a {
  display: inline-block;
  width: 200px;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.realEstateCompontent {
  width: 100%;
}

.realEstatePortals li a {
  color: rgb(57, 57, 57);
  text-align: center;
}
