.real-estate-portals {
  margin: 0 20px;
  width: 250px;
}
.real-estate-portals ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}
.real-estate-portals ul li a {
  width: 220px;
  padding: 10px;
  padding-left: 20px;
  margin: 5px 0;
  border-radius: 5px;
}
.real-estate-portals .title {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #037f97;
}
.real-estate-portals li a {
  text-align: left;
}