.legal-title {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #037f97;
  text-align: center;
  padding: 40px 0;
  margin-top: 0;

  background-color: #eee;
}
h3 {
  margin-top: 15px;
  margin-bottom: 5px;
  color: #037f97;
}
.legal-container li {
  margin-left: 30px;
}
.legal-title-parent {
  position: relative;
}
.legalBackButton {
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 0;
}
.google-play-text-parent {
  display: flex;
  justify-content: center;
}

dt, dd {
  display: inline-block;
}