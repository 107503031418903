.footer {
  position: relative;
  background-color: #037f97;
  padding-top: 20px;
  margin-top: 20px;
}
.google-play-text {
  text-align: center;
}
.google-play-button-wrapper {
  text-align: center;
}

.google-trademark {
  margin-top: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  text-align: end;
}

.footer-legal {
  display: flex;
  justify-content: space-around;
}

.footer-legal * {
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
}

.google-play-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrcode-footer {
  height: 140px;
  width: 140px;
  display: inline-block;
  margin: 10px 3px 20px 3px;
  cursor: pointer;
}

.qrcode-footer:hover {
  height: 146px;
  width: 146px;
  margin: 7px 0 17px 0;
}

.paypal-footer-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.paypal-footer-container a {
  color: RGB(57, 57, 57)
}
.paypal-footer-button {
  padding-top: 10px;
}

@media screen and (max-width: 770px) {
  .paypal-footer-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paypal-footer-button {
    padding-left: 6px;
    margin-right: 6px;
    margin-top: 4px;
  }
}

@media screen and (max-width: 500px) {
  .footer-legal {
    flex-direction: column;
  }
  .footer-legal * {
    margin-bottom: 5px;
  }

  .google-play-flex {
    flex-direction: column;
    position: relative;
  }

  .qrcode-footer {
    display: block;
    position: relative;
    margin-top: 0;
    margin-bottom: 20px;
    align-self: center;
  }
}
