.body {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.body-wrap {
  position: relative;
}
.body-wrap::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url("./logo-big.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 70%;
}

.right {
  margin: 0 20px;
}
.screenshot {
  margin-bottom: 10px;
}

.paypal {
  display: inline-block;
}

h2 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.box {
  margin: 30px 0;
  background: rgba(210, 210, 210, 0.4);
  padding: 15px;
}
.box:first-child {
  margin-top: 0;
}

.box p {
  text-align: center;
  margin: 10px 0;
  margin-left: 10px;
}

.steps-items p {
  text-align: left;
}

.body-subtitle {
  text-align: center;
}

.steps-items li {
  display: flex;
}

.steps-items h3 {
  color: #014b5a;
}

.backButton {
  display: block;
  background-color: #037f97;
  padding: 8px;
  margin: 5px;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 0;
}

.backButton:hover {
  -webkit-box-shadow: 0 0 2px 1px #037f97;
  -moz-box-shadow: 0 0 2px 1px #037f97;
  box-shadow: 0 0 2px 1px #037f97;
}

.advantage-disadvantage {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

.box-title {
  text-align: center;
  padding-bottom: 10px;
}

.advantage-disadvantage-div-item {
  display: flex;
  flex-shrink: 1;
  flex-basis: 100%;
  height: 90px;
  width: 300px;
  padding: 10px;
  border-radius: 2px;
}

.advantage-disadvantage li {
  position: relative;
  list-style-type: none;
  cursor: pointer;
  margin: 6px;

  -webkit-box-shadow: 0 0 5px 2px #58a833;
  -moz-box-shadow: 0 0 5px 2px #58a833;
  box-shadow: 3px 3px 5px #777;
  background: rgba(196, 196, 196, 0.4);
}

.arrow_box {
  position: absolute;
  background: white;
  border: 2px solid #c2e1f5;
  margin-top: 10px;
  left: -10%;
  right: -10%;
  z-index: 1;
}

.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: white;
  border-width: 30px;
  margin-left: -30px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: white;
  border-width: 36px;
  margin-left: -36px;
}

#disadvantage li:hover {
  background: rgba(189, 53, 29, 0.2);
}

#advantage li:hover {
  background: rgba(88, 168, 51, 0.2);
}

.thump {
  width: 50px;
  margin: auto 0;
}

.thumpText {
  display: inline-block;
  margin-left: 20px;
  align-self: center;
  width: 100%;
}

.link {
  color: #037f97;
}

.supportedCountriesFlags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.supportedCountriesFlags img {
  margin: 5px;
}

.paypal-donation {
  display: flex;
  align-items: center;
  margin: 5px 0px !important;
}

@media screen and (max-width: 1200px) {
  .real-estate-portals {
    display: none;
  }
  .body {
    margin-left: 20px;
  }
}
@media screen and (max-width: 800px) {
  .right {
    display: none;
  }
  .body {
    margin-right: 20px;
  }
}

@media screen and (max-width: 400px) {
  .advantage-disadvantage-div-item {
    width: 280px;
  }
  .arrow_box {
    left: -5%;
    right: -5%;
  }
}
@media screen and (max-width: 350px) {
  .advantage-disadvantage-div-item {
    width: 260px;
  }
  .body {
    margin-left: 2px;
    margin-right: 2px;
  }

  .advantage-disadvantage li {
    margin: 6px 0;
  }
}
